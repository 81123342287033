import React, { useEffect, createRef } from 'react';
import '../assets/css/FlightMapAnimation.css';

import lottie from 'lottie-web';
import animation from '../assets/animations/flightmap.json';

const FlightMapAnimation = () => {
  let animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {},
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, [animationContainer]);

  return (
    <div id="lottie">
      <div className="animation-container" ref={animationContainer} />
    </div>
  );
};

export default FlightMapAnimation;
