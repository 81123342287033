import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import silvan_merki from '../../assets/img/testimonials/silvan_merki.png';
import lea_wertheimer from '../../assets/img/testimonials/lea_wertheimer.jpg';

const Quote = () => {
  const quotes = [
    {
      customer: 'Silvan Merki, CCO und Head Marketing/Communications, Implenia',
      text: `Mit dem Contentboard von Benso haben wir jederzeit den Überblick über all unseren Content 
            in all unseren Marketing- und Kommunikationsinstrumenten. Es wird so zum rollierenden und laufend 
            aktualisierten Steuerungsinstrument für die integriert geführten Marketing- und Kommunikationsaktivitäten. 
            Viele nicht aufeinander abgestimmte Excel- und Power-Point-Planungen werden damit überflüssig. 
            Und Benso entwickelt das Tool laufend nach unseren Bedürfnissen weiter.`,
      image: silvan_merki,
    },
    {
      customer:
        'Léa Wertheimer, Leiterin Media Relations, Die Schweizerische Post AG',
      text: `Die Post verzeichnet zahlreiche Medienanfragen täglich. Damit wir die Kontakte mit den 
            Medienschaffenden erfassen, verteilen und bearbeiten können, benutzen wir das Contentboard. Dank des 
            modularen Aufbaus, ist es für unsere Bedürfnisse massgeschneidert und auch für nicht ganz so affine 
            Benutzerinnen und Benutzer einfach anzuwenden. Es erleichtert uns die tägliche Arbeit auf der Medienstelle der Post.`,
      image: lea_wertheimer,
    },
  ];

  const [active, setActive] = useState(0);

  const handleSetClick = (event) => {
    setActive(event.target.getAttribute('data-quote'));
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    pauseOnFocus: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {quotes.map((quote, i) => {
        return (
          <div className={'quote-content'} key={'quote-' + i}>
            <img
              className="quote-image"
              src={quote.image}
              alt={quote.customer}
            />
            <p>{quote.text}</p>
            <p>
              <i>{quote.customer}</i>
            </p>
          </div>
        );
      })}
    </Slider>
  );
};

export default Quote;
