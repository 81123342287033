import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import FlightmapAnimation from '../components/Flightmap-Animation';

import ganttchart from '../assets/img/ganttchart.png';
import flightmap from '../assets/img/flightmap.png';

import postfinance from '../assets/img/references/postfinance.png';
import implenia from '../assets/img/references/implenia.png';
import post from '../assets/img/references/post.png';
import Quote from '../components/quotes/quotes';

const IndexPage = ({ location }) => {
  const functionalities = [
    {
      title: 'Einfach',
      icon: 'fa-sync',
      text: `Das Contentboard ermöglicht dir und deinem Team eine einfache Datenerfassung`,
    },
    {
      title: 'Übersichtlich',
      icon: 'fa-search',
      text: `Schneller Überblick dank verschiedener Blickwinkel auf deine Daten`,
    },
    {
      title: 'Individuell',
      icon: 'fa-fingerprint',
      text: `Das Contentboard passt sich optimal auf deine Bedürfnisse an`,
    },
  ];
  const references = [
    {
      name: 'Implenia',
      image: implenia,
    },
    {
      name: 'PostFinance AG',
      image: postfinance,
    },
    {
      name: 'Die Schweizerische Post AG',
      image: post,
    },
  ];
  const themes = [
    {
      title: 'Gantt Chart',
      icon: 'fa-stream',
      text: `Mit dem Gantt Chart hast du die Zukunft immer im Griff`,
      image: ganttchart,
      alt: `Ganttchart`,
      features: [
        { text: `Alle Themen auf einen Blick` },
        { text: `Kategorisiere deine Bereiche` },
        { text: `Filtern deiner gewünschten Ansicht` },
      ],
    },
    {
      title: 'Flightmap',
      icon: 'fa-plane',
      text: `Mit der Flightmap kommen alle Vorhaben garantiert zum Fliegen`,
      image: flightmap,
      alt: `Flightmap`,
      features: [
        { text: `Übersicht aller Vorhaben` },
        { text: `Konfiguriere verschiedene Status` },
        { text: `Definiere deine Phasen` },
      ],
    },
  ];
  const usages = [
    {
      title: 'Kommunikation',
      icon: 'fa-comments',
      headline: `Keine Übersicht mehr, wer wann was und wo kommuniziert?`,
      text: `Das Contentboard eignet sich sehr gut für die Planung der Kommunikation. Interne wie auch externe 
            Kommunikationsmassnahmen lassen sich einfach im Contentboard erfassen.
      Die einfache Verwaltung der Daten ermöglicht es auch grossen Firmen stets den Überblick zu bewahren. Dafür ist die 
      Visualisierung zuständig. In dieser sind alle Kommunikationsmassnahmen übersichtlich auf einen Zeitstrahl abgebildet. Den Zeitstrahl
      lässt sich beliebig filtern und sortieren. So findet jeder garantiert die für ihn richtige Ansicht.`,
    },
    {
      title: 'Projektübersicht',
      icon: 'fa-project-diagram',
      headline: `Interessiert, welche Projekte existieren und wie der jeweiligen Status dazu ist?`,
      text: `Es ist schwierig einen Gesamtüberblick aller laufenden Projekte zu behalten. Wir schaffen nun Abhilfe. Der Flightplan
      sorgt dafür, dass du auf einer innovativen Visualisierung alle Projekte auf einen Blick siehst. Du kannst ableiten, welche Projekte
      erfolgreich abgeschlossen sind, welche gescheitert sind oder wie lange das Projekt noch dauert. Ausserdem kannst du wichtige
      Kennzahlen und Informationen selber zusammenstellen und auf der Visualisierung hinzufügen. Damit ist jeder für eine kompetente Auskunft abgesichert.`,
    },
  ];

  return (
    <Layout
      location={location}
      title={'Contentboard'}
      description={`Das Contentboard ist die optimale Software um
     mit einfachen Visualisierungen auch bei vielen Vorhaben den Überblick zu behalten.`}
    >
      <article id="main">
        <header className="major fligthBackground">
          <h2>Contentboard</h2>
          <p>Einfach. Übersichtlich. Individuell.</p>
        </header>

        <section className="wrapper style5 special">
          <div className="inner">
            <header className="major">
              <h2>Mit Einfachheit Verständnis schaffen</h2>
              <p>
                Mit dem Contentboard hast du deine Vorhaben stets im Blick.
                Durch einfach verständliche Visualisierungen kannst du deine
                komplexen Abläufe ganz simpel jedem aufzeigen.
              </p>
              <div className="row aln-center">
                <div className="col-8 col-12-small">
                  <FlightmapAnimation />
                </div>
              </div>
            </header>
            <ul className="contentboard-theme">
              {themes.map((theme, index) => {
                return (
                  <li key={index}>
                    <p className="major-icon-center-paragraph">
                      <span
                        className={
                          theme.icon +
                          ' major icon solid contentboard-theme-icon'
                        }
                      ></span>
                    </p>
                    <div className="row gtr-50 gtr-uniform">
                      <div className="col-12">
                        <span className="image fit">
                          <img
                            className="contentboard-theme-image"
                            src={theme.image}
                            alt={theme.alt}
                          />
                        </span>
                      </div>
                      <div className="col-12">
                        <h3>{theme.title}</h3>
                        <div className="contentboard-theme-feature">
                          <p>{theme.text}</p>
                          <ul>
                            {theme.features.map((feature, index) => {
                              return <li key={index}>{feature.text}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section id="three" className="wrapper style2 special">
          <div className="inner">
            <header className="major">
              <h2>Funktionalitäten</h2>
            </header>
            <ul className="contentboard-key-features">
              {functionalities.map((functionality, index) => {
                return (
                  <li
                    key={index}
                    className={functionality.icon + ' icon solid'}
                  >
                    <h3>{functionality.title}</h3>
                    <p>{functionality.text}</p>
                  </li>
                );
              })}
              <li className="">
                <h3 style={{ visibility: 'hidden' }}>Interesse geweckt?</h3>
                <ul>
                  <li>
                    <Link to="/kontakt/" className="button primary">
                      Interesse geweckt?
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div></div>
        </section>

        <section className="wrapper style5 special">
          <div className="inner">
            <header className="major">
              <h2>Einsatzmöglichkeiten</h2>
              <p>
                Das Contentboard ist vielfältig und bietet diverse Möglichkeiten
                wie es eingesetzt werden kann. Hier zwei Beispiele wie es
                aktuell verwendet wird.
              </p>
            </header>
            <ul className="contentboard-theme">
              {usages.map((usage, index) => {
                return (
                  <li key={index}>
                    <p className="major-icon-center-paragraph">
                      <span
                        className={
                          usage.icon +
                          ' major icon solid contentboard-theme-icon'
                        }
                      ></span>
                    </p>
                    <div className="row gtr-50 gtr-uniform">
                      <div className="col-12"></div>
                      <div className="col-12">
                        <h3>{usage.title}</h3>
                        <p>{usage.headline}</p>
                        <p>{usage.text}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="wrapper style5 special">
          <div className="inner">
            <header className="major">
              <h2>Zufriedene Kunden</h2>
              <p></p>
            </header>
            <div
              className="row gtr-50 gtr-uniform aln-center"
              style={{ paddingBottom: '1em' }}
            >
              {references.map((ref) => {
                return (
                  <div key={ref.name} className="col-4 col-6-small">
                    <span className="image fit" style={{ padding: '0.5em' }}>
                      <img src={ref.image} alt={ref.name} />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="wrapper style5 special">
          <div className="inner">
            <header className="major">
              <h2>Kundenmeinungen</h2>
              <p></p>
            </header>
            <Quote />
          </div>
        </section>
      </article>
    </Layout>
  );
};
export default IndexPage;
